import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@futura-library/ui-library';
import styles from './AlertModal.module.css';
import PaymentContext from '../../context/Payment/Context';
import statesDataJson from '../../utils/data.json';
import GlobalContext from '../../context/Global/Context';

/**
 * Component for modals
 * @param {object} data All the data information to make the modal
 * @param {function} modal Function (useState) to close and open modal
 * @param {boolean} discardOption Variable to change the construction of the secondary button
 * @returns Modal
 */

const AlertModal = ({ data, modal, discardOption }) => {
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const { country } = globalContext;
  const { createAddressFunction, hasAddressFunction } = paymentContext;
  const {
    title,
    description,
    button,
    discard,
    imgAlert,
    urlRedirect,
    target,
    discardLink,
    address,
  } = data;

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;

  useEffect(() => {
    $html.style.height = '100vh';
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
  }, [data]);

  const loadLocalAddress = () => {
    const { shippingAddress } = address;
    const stateName = statesDataJson.find((stateKey) => {
      return stateKey.code === shippingAddress?.state;
    });
    const valuesNew = {
      Street: shippingAddress?.streetName,
      Address_Number: shippingAddress?.streetNumber,
      References: shippingAddress?.additionalStreetInfo,
      Zip_Code: shippingAddress?.postalCode,
      Neighborhood: shippingAddress?.city,
      State: [
        stateName?.name,
        stateName?.name,
        stateName?.name,
        stateName?.name,
      ],
      ChangePaymentType: true,
    };
    const valuesNewCO = {
      Street: shippingAddress?.streetName,
      Address_Number: shippingAddress?.streetNumber,
      References: shippingAddress?.apartment,
      Neighborhood: shippingAddress?.additionalStreetInfo,
      State: [shippingAddress?.city],
      Department: shippingAddress?.department,
      ChangePaymentType: true,
    };
    createAddressFunction(country === 'MX' ? valuesNew : valuesNewCO);
    hasAddressFunction(true);
    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
    modal(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <img width={64} height={64} src={imgAlert} alt="Alert" />
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
        {data.onlyPrimaryButton ? (
          <div>
            {data.isButtonPrimaryAction ? (
              <Button
                id="AlertModal_PrimaryButton"
                className={styles.button}
                color="blue"
                fullWidth
                label={button}
                size="medium"
                variant="filled"
                isButton
                onClick={() => {
                  modal(false);
                }}
              />
            ) : (
              <Button
                id="AlertModal_PrimaryButton"
                className={styles.button}
                color="blue"
                fullWidth
                href={urlRedirect}
                target={target || '_self'}
                rel={target === '_blank' ? 'noopener noreferrer' : ''}
                label={button}
                size="medium"
                variant="filled"
              />
            )}
          </div>
        ) : (
          <>
            <Button
              id="AlertModal_PrimaryButton"
              className={styles.button}
              color="blue"
              fullWidth
              href={urlRedirect}
              target={target || '_self'}
              rel={target === '_blank' ? 'noopener noreferrer' : ''}
              label={button}
              size="medium"
              variant="filled"
            />
            {discardOption ? (
              <a
                id="AlertModal_SecondaryButton"
                href={discardLink}
                className={styles.discard}
              >
                {discard}
              </a>
            ) : (
              <button
                id="AlertModal_SecondaryButton"
                onClick={() => {
                  if (address) {
                    loadLocalAddress();
                  } else {
                    $html.style.removeProperty('height');
                    $body.style.removeProperty('overflow');
                    $body.style.removeProperty('position');
                    $body.style.removeProperty('top');
                    $body.style.removeProperty('width');
                    window.scrollTo(0, scrollPosition);
                    modal(false);
                  }
                }}
                type="button"
                className={styles.discard}
              >
                {discard}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

AlertModal.propTypes = {
  data: PropTypes.shape.isRequired,
  modal: PropTypes.func.isRequired,
  discardOption: PropTypes.bool,
};

AlertModal.defaultProps = {
  discardOption: false,
};

export default AlertModal;
