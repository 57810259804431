/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import PaymentContext from '../../../../context/Payment/Context';
import { priceCheck } from '../../../../assets/images/Global';
import AlertModal from '../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../utils/stock';
import GlobalContext from '../../../../context/Global/Context';
import SliderButton from './components/SliderButton';
import dataPayments from './dataPayments';
import styles from './MethodsFlow.module.css';

const MethodsFlow = (props) => {
  const totalPrice = props?.totalPrice || 0
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const [preventModal, setPreventModal] = useState(false);
  const { dashboard } = paymentContext;
  const { country, isCitaFlow, isOnlyComplementaryPayment, isOnlyAdvance } =
    globalContext;
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );

  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );

        if (!areThereCartsWithoutEnoughStock(upToDateCartData.carts)) return;
        setPreventModal(true);
      } catch {
        // eslint-disable-next-line no-console
        setPreventModal(false);
      }
    })();
  }, [dashboard]);

  return (
    <>
      {totalPrice === 0 ? dataPayments[country]['closeOrder']() :<>
      <div className={styles.titleContainer}>
        <div className={styles.circleTitle}>
          <p>1</p>
        </div>
        <p className={styles.label}>¿Cómo deseas pagar?</p>
      </div>
      <div className={styles.containerSlider}>
        {dataPayments[country].payments.map((method, index) => {
          return (
            method.show && (
              <SliderButton key={method.type} data={method} index={index} />
            )
          );
        })}
      </div>
      { dataPayments[country][dashboard]()}
      </>}
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
    </>
  );
};

export default MethodsFlow;
