/* eslint-disable */

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Input, Dropdown, Button, Banner } from '@futura-library/ui-library';
import {
  cardVisa,
  mastercard,
  amex,
  cardCvc,
  creditBlank,
  lock,
  priceCheck,
} from '../../../../../../../../assets/images/Global';
import PaymentContext from '../../../../../../../../context/Payment/Context';
import styles from '../../CreditCard.module.css';
import { data, paymentInstallments, currencyInstallments } from './data';
import {
  availableCountries,
  fetchMPAccessTokenByCountry,
} from '../../../../../../../../utils/locale';
import {
  getPriceWithInstallments,
  getTotalPrice,
} from '../../../../../../../../utils/cardForm';
import organizeDataPayments from '../../../../../../../../methods/organizeDataPayments';
import axiosPost from '../../../../../../../../methods/cashPayments/mx/axiosPost';
import ThankYouContext from '../../../../../../../../context/ThankYou/Context';
import ProductContext from '../../../../../../../../context/Product/Context';
import totalPriceCarts from '../../../../../../../../hooks/totalCarts';
import AlertModal from '../../../../../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../../../utils/stock';
import GlobalContext from '../../../../../../../../context/Global/Context';
import sendFreshpaintEvent from '../../../../../../../../utils/sendFreshpaintEvent';
import errorHandler from '../../../../../../../../utils/errorHandler';

const CardForm = () => {
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const [mp, setMp] = useState(null);
  const [cardForm, setCardForm] = useState(null);
  const [cardType, setCardType] = useState('');
  const [issuerId, setissuerId] = useState('');
  const [paymentMethodId, setPMethodId] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [installments, setInstallments] = useState([]);
  const [installmentsNum, setInstallmentsNum] = useState(0);
  const [mpToken, setMPToken] = useState('');
  const [stripeToken, setStripeToken] = useState('');
  const [conektaToken, setConektaToken] = useState('');
  const [loadingCardButton, setLoadingCardButton] = useState(false);
  const [errorPaymentConekta, setErrorPaymentConekta] = useState(null);
  const [insSel, setInstallmentSelected] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasErrorName, setHasErrorName] = useState(null);
  const [hasErrorCard, setHasErrorCard] = useState(null);
  const [hasErrorMonth, setHasErrorMonth] = useState(null);
  const [hasErrorCVC, setHasErrorCVC] = useState(null);
  const [hasErrorDocumentNumber, setHasErrorDocumentNumber] = useState(null);
  const [hasErrorAccentMark, setHasErrorAccentMark] = useState(null);
  const [preventModal, setPreventModal] = useState(false);
  const [tokensReady, setTokensReady] = useState(false);
  const [showDropDownMonths, setShowDropDownMonths] = useState(false);
  const [valuesSelected, setValuesSelected] = useState({
    document: {},
    number: '',
  });
  const [deviceId, setDeviceId] = useState('');
  const { carts, couponsCode, productsWithDiscount, order } = productContext;
  const {
    isCitaFlow,
    isOnlyAdvance,
    isOnlyComplementaryPayment,
    appointmentRedirect,
    redirectUrl,
    buttonUrl: buttonToDashboard,
    publicKey,
    name: nameContext,
    country,
    email,
    origin,
    setEmptyAddressFunction,
  } = globalContext;
  const totalPrice = totalPriceCarts(carts, false, order);
  const { hasCard, hasAddress, hasMonths, setMonths, setInstallmentsTax, shouldResetCardForm, resetCardForm } = paymentContext;
  const { setDataCard } = thankYouContext;
  const { name, nameHolder, info, cardHolder, cvcHolder, monthHolder } = data;
  const dataCardCheckout = organizeDataPayments();
  const { payer, cartData, salesAgentId, nameAgent, clinicId } =
    dataCardCheckout;
  const customerId = payer.customerId;
  const [expirationError, setExpirationError] = useState('');
  const gatewaySelector = new URLSearchParams(window.location.search).get(
    'gtwSelect'
  );
  const priority = 0;
  const amount = String(totalPrice);

  const hasDiscount = couponsCode.length > 0 ? true : false;

  const date_card = useRef(null);
  const cvv_card = useRef(null);

  const gatewayValidator = (gate) => {
    if (gate !== 'mercadopago' && gate !== 'conekta' && gate !== 'stripe') {
      return 'mercadopago';
    }

    return gate;
  };

  const gateway = gatewayValidator(String(gatewaySelector));

  const cartsCheck = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );

  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  const dataToFreshpaint = {
    distinct_id: payer.customerId,
    customer_id: payer.customerId,
    product_id: carts[0]?.lineItems[0]?.id,
    cart_id: carts[0]?.id,
    product_quantity: carts[0]?.lineItems.length,
    public_key: publicKey,
    country_ops: country,
    currency: availableCountries[country.toLocaleLowerCase()].currency,
    payment_method: 'card',
    product_category: 'appointment',
    product: `appointment-${country.toLocaleLowerCase()}`,
    payment_type: 'Complete',
    platform: 'checkout_futura',
    transaction_amount: carts[0]?.totalPrice.centAmount / 100,
    name: nameContext,
    email,
  };

  useEffect(() => {
    if (couponsCode.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-MX',
          price:
            product?.discountedPricePerQuantity[0]?.discountedPrice?.value
              .centAmount,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      cartData.totalLineItemQuantity = productsArray.length;
      cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  useEffect(() => {
    // Init MercadoPago, Stripe & Conekta
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY);
    window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY);
    const countryKeyCalc = fetchMPAccessTokenByCountry(country);
    if (window.MercadoPago) {
      const mpInstance = new window.MercadoPago(countryKeyCalc.key, {
        locale: countryKeyCalc.locale,
      });
      setMp(mpInstance);
    }

    let securityInterval = setInterval(() => {
      if (window['deviceId']) {
        setDeviceId(window['deviceId']);
        clearInterval(securityInterval);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    // Reset Issuer
    if (cardNumber.length < 6) {
      setissuerId('');
    }
  }, [cardNumber]);

  useEffect(() => {
    if (!cardForm) {
      const newCardForm = mp?.cardForm({
        amount,
        autoMount: true,
        form: {
          id: 'form-checkout',
          cardholderName: {
            id: 'name',
            placeholder: nameHolder,
          },
          // cardholderEmail: {
          //   id: 'form-checkout__cardholderEmail',
          //   placeholder: 'E-mail',
          // },
          cardNumber: {
            id: 'card',
            placeholder: cardHolder,
          },
          cardExpirationDate: {
            id: 'month',
            placeholder: monthHolder,
          },
          securityCode: {
            id: 'cvc',
            placeholder: cvcHolder,
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Cuotas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Document type',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número de documento',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Banco emisor',
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            console.log('mounted')
            if (error)
              return console.warn('Form Mounted handling error: ', error);
          },
          onFormUnmounted: (error) => {
            if (error)
              return console.warn('Form Unmounted handling error: ', error);
          },
          onIdentificationTypesReceived: (error, identificationTypes) => {
            if (error)
              return console.warn('identificationTypes handling error: ', error);
          },
          onPaymentMethodsReceived: (error, paymentMethods) => {
            if (error)
              return console.warn('paymentMethods handling error: ', error);
            const [pmData] = paymentMethods;
            const { id, issuer, payment_type_id } = pmData;
            setCardType(id);
            setissuerId(issuer?.id);
            setPMethodId(payment_type_id);
          },
          onIssuersReceived: (error, issuers) => {
            if (error) return console.warn('issuers handling error: ', error);
          },
          onInstallmentsReceived: (error, installmentsResponse) => {
            if (error)
              return console.warn('installments handling error: ', error);
            const installmentsArray = installmentsResponse.payer_costs;
            console.log('INSTALLMENTS', installmentsArray);
            let installmentsState = installmentsArray.map((value, index) => {
              return {
                id: index,
                label: value.recommended_message,
                selected: false,
                value: value.installments,
                installmentRate: value.installment_rate,
                months: value.installments,
                taxe: value.installment_rate,
              };
            });

            setInstallments(installmentsState);
            setInstallmentSelected(installmentsState[0]);
          },
          onCardTokenReceived: (error, token) => {
            if (error) return console.warn('Token handling error: ', error);
          },
        },
      });
      setCardForm(newCardForm);
    }

    if (!cardType) {
      setErrorPaymentConekta('Hubo un problema con el método de pago, por favor recarga la página.');
    }

    return () => {
      // Unmount cardform if the user change payment method
      if (cardForm) {
        cardForm && cardForm?.unmount();
      }
    };

  }, [mp, cardForm]);

  useEffect(() => {
    if (shouldResetCardForm) {
      setCardNumber('');
      setShowDropDownMonths(false);
      setSecurityCode('');
      setCardExpirationDate('');
      setInstallmentSelected('');
      setInstallmentsTax(0);
      setMonths(1);
      resetCardForm();
      setCardForm(null);
    }
  }, [shouldResetCardForm, resetCardForm, cardForm]);

  const buildCheckoutReq = useCallback(() => {
    const newCheckoutReq = {
      country: availableCountries[country.toLowerCase()]?.countryName || 'Invalid Country',
      priority: +priority,
      payer: payer,
      token: {
        stripeId: stripeToken,
        mercadoPagoId: mpToken,
        conektaId: conektaToken,
      },
      cartData: cartData,
      productType: 'prod',
      cardType,
      paymentTypeId: paymentMethodId,
      transactionType: 'product',
      paymentMethodType: 'card',
      installments: +paymentContext.months,
      installmentsTax: paymentContext.installmentsTax,
      // productKeyName: 'simple-mx',
      // paymentLinkKey: null,
      customerId: payer.customerId,
      // source: 'link',
      arrayExtraProducts: [],
      discountPrice: hasDiscount,
      installmentsType:
      installmentsNum > 1 ? 'apply_to_price' : 'discount_one_payment',
      salesAgentId,
      nameAgent,
      clinicId,
      deviceId,
      origin,
    };
    return newCheckoutReq;
  }, [
    cardType,
    cardholderName,
    conektaToken,
    country,
    customerId,
    gateway,
    installments,
    mpToken,
    paymentMethodId,
    priority,
    stripeToken,
    deviceId,
  ]);

  const checkNameAccentMark = (name) => {
    var accent_map = ['á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú'];
    var validate = false;
    accent_map.forEach((accent) => {
      if (name.indexOf(accent) > 0) {
        validate = true;
      }
    });
    return validate;
  };

  const checkErrors = (e) => {
    e.preventDefault();
    e.stopPropagation();

    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          cartsCheck
        );

        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          setPreventModal(false);
          const month =
            cardExpirationDate.split('/')[0] + cardExpirationDate.split('/')[1];

          const regName = /^[a-zA-Z]+ [a-z ,.'-]+$/i;
          const regCard = /^[0-9].{14,}$/;
          const regMonth = /\b\d{4,}\b/g;
          // const regCVC = /\b\d{3,}\b/g;

          const testName = !regName.test(cardholderName);
          const testCard = !regCard.test(cardNumber);
          const testMonth = !regMonth.test(month);
          // const testCVC = !regCVC.test(securityCode);
          const testNameAccent = checkNameAccentMark(cardholderName);

          if (
            testName ||
            testCard ||
            testMonth ||
            // testCVC ||
            testNameAccent ||
            (country !== 'MX' && valuesSelected.number === '')
          ) {
            if (testNameAccent) {
              setHasErrorName(null);
            } else {
              setHasErrorName(testName ? true : null);
            }
            setHasErrorAccentMark(testNameAccent ? true : null);
            setHasErrorCard(testCard ? true : null);
            setHasErrorMonth(testMonth ? true : null);
            // setHasErrorCVC(testCVC ? true : null);
            setHasErrorDocumentNumber(
              valuesSelected.number === '' ? true : null
            );
            setLoadingCardButton(false);
          } else {
            setHasErrorAccentMark(false);
            handleSubmit();
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  const handleSubmit = useCallback(async () => {
    setHasErrorName(null);
    setHasErrorCard(null);
    setHasErrorMonth(null);
    setHasErrorCVC(null);
    setHasErrorDocumentNumber(null);
    setLoadingCardButton(true);
    const card = cardNumber.replace(/ /g, '');

    try {
      const newToken = await mp.createCardToken({
        cardNumber: card,
        cardholderName,
        cardExpirationMonth: cardExpirationDate.split('/')[0],
        cardExpirationYear: '20' + cardExpirationDate.split('/')[1],
        securityCode,
        identificationType: valuesSelected?.document?.value
          ? valuesSelected?.document?.value
          : '',
        identificationNumber: valuesSelected?.number
          ? valuesSelected?.number
          : '',
      });

      setMPToken(newToken?.id);

      const thankYouDataCard = {
        digits: newToken.last_four_digits,
        date: newToken.date_created,
        payment: installmentsNum,
      };

      localStorage.setItem('tyDataCard', JSON.stringify(thankYouDataCard));
    } catch (error) {
      setLoadingCardButton(false);
      setTokensReady(false);
      setErrorPaymentConekta('El método de pago es erróneo');
    }

    const cardInfo = {
      name: cardholderName,
      number: cardNumber.replace(/ /g, ''),
      cvc: securityCode,
      exp_month: cardExpirationDate.split('/')[0],
      exp_year: '20' + cardExpirationDate.split('/')[1],
    };

    const stripeData = {
      ...cardInfo,
      address_country: 'México',
    };

    if (country === 'MX') {
      await window.Stripe.createToken(stripeData, (_, response) => {
        if (response.id) {
          setStripeToken(response?.id);
        } else {
          setErrorPaymentConekta('El método de pago es erróneo');
          setHasError(true);
          setLoadingCardButton(false);
        }
      });
    }

    const conektaData = {
      card: {
        ...cardInfo,
      },
    };

    if (country === 'MX') {
      await window.Conekta.Token.create(
        conektaData,
        (successEvent) => {
          setConektaToken(successEvent?.id);
        },
        (error) => {
          setErrorPaymentConekta('El método de pago es erróneo');
          setHasError(true);
          setLoadingCardButton(false);
        }
      );
    }
  }, [
    cardExpirationDate,
    cardNumber,
    cardholderName,
    country,
    mp,
    securityCode,
    installmentsNum,
    valuesSelected.number,
  ]);

  useEffect(() => {
    if (country === 'MX') {
      if (conektaToken && mpToken && stripeToken) {
        setTokensReady(true);
      } else {
        setTokensReady(false);
      }
    } else {
      if (mpToken) {
        setTokensReady(true);
      } else {
        setTokensReady(false);
      }
    }
  }, [conektaToken, mpToken, stripeToken]);

  useEffect(() => {
    if (tokensReady) {
      const checkoutReq = buildCheckoutReq();
      if (country !== 'MX') {
        checkoutReq.docTypeCard = valuesSelected.document.value;
        checkoutReq.docValueCard = valuesSelected.number;
        checkoutReq.gateway = 'mercadopago';
      }
      let tyData = JSON.parse(localStorage.getItem('tyDataCard'));
      tyData.cardType = checkoutReq.cardType;
      localStorage.setItem('tyDataCard', JSON.stringify(tyData));
      axiosPost(checkoutReq)
        .then((res) => {
          dataToFreshpaint.attempted_status = true;
          if (origin === 'appointmentv2') {
            sendFreshpaintEvent(
              'New Checkout - Payment Attempted',
              dataToFreshpaint
            );
          }
          setDataCard(res.data);
          if (res.data.status === 'in_process') {
            localStorage.setItem('in_process', true);
          } else {
            localStorage.setItem('in_process', false);
          }
          if (
            appointmentRedirect &&
            res.data.status !== 'in_process' &&
            origin !== 'appointmentv2'
          ) {
            const urlDashboard = `${buttonToDashboard}&token=${publicKey}54${publicKey}`;
            const urlEncoded = encodeURIComponent(urlDashboard);
            let joiner = redirectUrl && redirectUrl.indexOf('?') >= 0 ? '&' : '?&';
            const urlToAppointment = `${redirectUrl}${joiner}transactionId=${res.data.transactionId}&redirectUrl=${urlEncoded}`;
            window.location.href = urlToAppointment;
          } else {
            window.location.replace(`${window.location.origin}/payment`);
          }
        })
        .catch((error) => {
          console.log("ERROR ->", error);
          dataToFreshpaint.attempted_status = false;
          if (origin === 'appointmentv2') {
            sendFreshpaintEvent(
              'New Checkout - Payment Attempted',
              dataToFreshpaint
            );
          }
          setMPToken('');
          setStripeToken('');
          setConektaToken('');
          setErrorPaymentConekta('El método de pago es erróneo');
          setHasError(true);
          setLoadingCardButton(false);
        });
    } else {
      setErrorPaymentConekta('Hubo un problema con el método de pago, por favor recarga la página.');
    }
  }, [tokensReady]);

  useEffect(() => {
    // Response Alert for payment
    setTimeout(() => {
      setHasError(false);
      setErrorPaymentConekta(null);
    }, 7000);
  }, [hasError]);

  const handle_focus_card = (e) => {
    let value = e.target.value;
    value = value.replace(/\s/g, '');

    let field = e.target;
    let position = field.selectionEnd;
    let length = field.value.length;

    field.value = field.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();

    field.selectionEnd = position +=
      field.value.charAt(position - 1) === ' ' &&
        field.value.charAt(length - 1) === ' '
        ? 1
        : 0;

    if (value.length === 16) {
      setCardNumber(field.value);
      date_card.current.focus();
    } else {
      setCardNumber(field.value);
    }
  };

  const validateExpiration = (date) => {
    var dateArray = date.split('/');
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = parseInt(today.getFullYear().toString().slice(-2));
    const validate =
      parseInt(dateArray[0]) > 12 ||
      parseInt(dateArray[0]) < 0 ||
      parseInt(dateArray[1]) < 22 ||
      (parseInt(dateArray[0]) < month && parseInt(dateArray[1]) <= year);
    setExpirationError(validate);
  };

  const handle_focus_month = (e) => {
    const value = e.target.value;
    if (value.length === 5) {
      validateExpiration(value);
      cvv_card.current.focus();
      setCardExpirationDate(value);
    } else {
      setCardExpirationDate(value);
    }
  };

  const render_installments = () => {
    const months = [];
    const dropdownData = [];
    installments.forEach((installment, index) => {
      let label = installment.label;
      dropdownData.push({
        id: installment.id,
        value: installment.value,
        label: label,
        installmentRate: installment.installmentRate,
      });
    });
    return dropdownData;
  };

  useEffect(() => {
    const showForNonAmex = cardType !== 'amex' && cardNumber.length === 19 && hasMonths;
    const showForAmex = cardType === 'amex' && cardNumber.length === 18 && hasMonths;
    if (showForNonAmex || showForAmex) {
      if (installments.length !== 0) {
        setShowDropDownMonths(true);
        // setInstallments([]);
      }
    } else {
      setInstallmentSelected('');
      setInstallmentsTax(0);
      setMonths(1);
      setShowDropDownMonths(false);
    }
  }, [cardNumber, installments, hasMonths]); 

  const validateDocumentNumber = (value) => {
    const regexAlfa = /[^A-Za-z0-9]/g;
    var regexNumeric = /^[0-9\b]+$/;

    if (valuesSelected.document.regex === 'alfa' && !regexAlfa.test(value)) {
      setValuesSelected({
        ...valuesSelected,
        number: value,
      });
    }

    if (
      (valuesSelected.document.regex === 'num' && regexNumeric.test(value)) ||
      value === ''
    ) {
      setValuesSelected({
        ...valuesSelected,
        number: value.replace(/\D/g, ''),
      });
    }
  };

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      <form id="form-checkout" onSubmit={checkErrors} className={styles.form}>
        {country !== 'MX' && (
          <>
            <div className={styles.inputMargin}>
              <Dropdown
                id="documnent_id"
                fullWidth
                label="Tipo de documento"
                options={data.document[country]}
                value={valuesSelected.document}
                onChange={(selection) => {
                  if (selection.id !== 0) {
                    setValuesSelected({
                      ...valuesSelected,
                      document: {
                        id: selection.id,
                        label: selection.label,
                        selected: true,
                        value: selection.value,
                        regex: selection.regex,
                      },
                      number: '',
                    });
                  }
                }}
              />
            </div>
            <div className={styles.inputMargin}>
              <Input
                id="form-checkout__identificationNumber"
                type="text"
                error={null}
                label={data.numDocumento}
                disabled={valuesSelected.document.id === 0}
                placeholder={data.holderDocumento}
                value={valuesSelected.number}
                onChange={(e) => validateDocumentNumber(e.target.value)}
                variant="outline"
                pattern={null}
                maxLength={20}
                fullWidth
              />
            </div>
          </>
        )}
        {hasErrorDocumentNumber && country !== 'MX' && (
          <div className={styles.inputMargin}>
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">
                Ingresa el número de documento
              </span>
            </div>
          </div>
        )}
        <div className={styles.inputMargin}>
          <Input
            id="name"
            type="text"
            error={null}
            label={name}
            placeholder={nameHolder}
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
            variant="outline"
            pattern={null}
            fullWidth
          />
          {hasErrorName && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">
                Ingresa el nombre que aparece en tu tarjeta
              </span>
            </div>
          )}
          {hasErrorAccentMark && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">Agrega tu nombre sin acentos</span>
            </div>
          )}
        </div>
        <div className={styles.inputMarginCard}>
          <label htmlFor="card">{info}</label>
          <img
            className={styles.iconCardHolder}
            width={24}
            height={24}
            src={creditBlank}
            alt="Default Card"
          />
          {issuerId === 166 && (
            <img
              className={styles.iconCardHolder}
              width={24}
              height={24}
              src={cardVisa}
              alt="Visa"
            />
          )}
          {issuerId === 162 && (
            <img
              className={styles.iconCardHolder}
              width={24}
              height={24}
              src={mastercard}
              alt="Mastercard"
            />
          )}
          {issuerId === 157 && (
            <img
              className={styles.iconCardHolder}
              width={24}
              height={24}
              src={amex}
              alt="Amex"
            />
          )}
          <img
            className={styles.iconCardCvc}
            width={24}
            height={24}
            src={cardCvc}
            alt="CVC"
          />
          <input
            className={styles.inputPrincipal}
            id="card"
            type="text"
            placeholder={cardHolder}
            onChange={handle_focus_card}
            value={cardNumber}
            pattern="[0-9 ]+"
            maxLength={19}
          />
          <div className={styles.containerMonths}>
            <input
              //style={{display: "none"}}
              id="month"
              type="text"
              placeholder={monthHolder}
              maxLength={5}
              value={cardExpirationDate}
              onChange={handle_focus_month}
              onBlur={handle_focus_month}
              onKeyUp={handle_focus_month}
              ref={date_card}
            />
            <input
              id="cvc"
              type="password"
              placeholder={cvcHolder}
              pattern="[0-9]+"
              value={securityCode}
              onChange={(e) => setSecurityCode(e.target.value)}
              maxLength={4}
              ref={cvv_card}
            />
          </div>
          {hasErrorCard && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">
                El número de la tarjeta es invalido
              </span>
            </div>
          )}
          {expirationError && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">Fecha de expiración inválida</span>
            </div>
          )}
          {hasErrorMonth && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">
                Ingresa fecha de expiración de tu tarjeta
              </span>
            </div>
          )}
          {hasErrorCVC && (
            <div className="ErrorContainer">
              <span className="material-icons ErrorIcon">error</span>
              <span className="ErrorMessage">
                Ingresa el código de seguridad{' '}
              </span>
            </div>
          )}
        </div>
        <div>
          {showDropDownMonths && country === 'MX' && (
            <div className={styles.installments}>
              <Dropdown
                disabled={hasCard}
                fullWidth
                label="Difiere tus pagos"
                options={render_installments()}
                value={insSel}
                id="form-checkout__installments"
                // resetThenSet={function noRefCheck() {}}
                // toggleItem={(selection) => setInstallmentSelected(selection)}
                onChange={(selection) => {
                  setInstallmentSelected(selection);
                  setInstallmentsNum(selection.value);
                  paymentContext.selectInstallments(selection.value);
                  setInstallmentsTax(selection.installmentRate);
                  setMonths(selection.value);
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.securedContainer}>
          <div className={styles.secured}>
            <img width={16} height={16} src={lock} alt="Lock Black" />
            <p className={styles.securedText}>
              Pago seguro con encriptación SSL
            </p>
          </div>
        </div>
        <div className={styles.button}>
          {hasError && (
            <div className={styles.banner}>
              <Banner
                content="Intentalo nuevamente o selecciona un método de pago distinto."
                fullWidth
                title={errorPaymentConekta}
                variant="critical"
              />
            </div>
          )}
          {paymentContext.totalPrice && (
            <Button
              id="Check_Flow_Card"
              color="blue"
              fullWidth
              isButton
              //label={`Pagar ${localStorage.getItem('totalPriceCarts')}`}
              label={`Pagar ${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '
                }${getTotalPrice(
                  paymentContext,
                  country
                )} ${country.toLocaleLowerCase() !== 'pe'
                  ? availableCountries[country.toLocaleLowerCase()].currency
                  : ''
                }`}
              rel="noopener noreferrer"
              size="medium"
              variant="filled"
              type="submit"
              loading={loadingCardButton}
            />
          )}
        </div>
        <div style={{ display: 'none' }}>
          <select name="installments" id="form-checkout__installments"></select>
          <select name="issuer" id="form-checkout__issuer"></select>
          {country === 'MX' && (
            <input
              type="text"
              name="identificationNumber"
              id="form-checkout__identificationNumber"
            />
          )}
          <select
            name="identificationType"
            id="form-checkout__identificationType"
            value={valuesSelected.document.value}
          >
            <option value="">Choose NEW after types appear</option>
          </select>
        </div>
        {/* <div className={styles.inputMargin}>
        <Checkbox checked={false} label={save} />
      </div> */}
      </form>
    </>
  );
};

export default CardForm;
