/* eslint-disable */
import { useEffect, useState, useContext } from 'react';
import { Button, Input, Dropdown, Banner } from '@futura-library/ui-library';
import ThankYouContext from '../../../../../../../context/ThankYou/Context';
import { pseLogo } from '../../../../../../../assets/images/Global';
import { getPSEBanks } from '../../../../../../../methods/cashPayments/co/pse';
import styles from '../../../MethodsFlow.module.css';
import stylesForm from '../../CreditCard/CreditCard.module.css';
import data from '../../../data';
import formPSE from './formPSE';
import createPayuScripts from '../utils/psePayu';
import organizeDataPayments from '../../../../../../../methods/organizeDataPayments';
import axiosPost from '../../../../../../../methods/cashPayments/mx/axiosPost';
import PaymentContext from '../../../../../../../context/Payment/Context';
import GlobalContext from '../../../../../../../context/Global/Context';

const PSEForm = ({ setFormPSE, setPSELink }) => {
  const thankYouContext = useContext(ThankYouContext);
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const { setEmptyAddressFunction, origin } = globalContext;
  const { hasAddress } = paymentContext;
  const { setDataPSE } = thankYouContext;
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorPSE, setErrorPSE] = useState({
    error: '',
    status: false,
    emptyBank: false,
    emptyClientType: false,
    emptyDocumentType: false,
    emptyDocument: false,
  });
  const [banks, setBanks] = useState([
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: 0,
    },
  ]);
  const [valuesSelected, setValuesSelected] = useState({
    bank: {},
    clientType: {},
    document: {},
    number: '',
  });
  const { titlePSE, buttonPSE1 } = data.pse;
  let dataToPSE = organizeDataPayments();

  useEffect(() => {
    createPayuScripts();
    getPSEBanks()
      .then((response) => {
        const banksArray = response?.data?.banks;
        let banksState = [];
        banksArray.map((value) => {
          const array = {
            id: value?.id,
            label: value?.description,
            selected: false,
            value: value?.pseCode,
          };
          banksState.push(array);
        });
        banksState.shift();
        banksState.unshift({
          id: 0,
          label: 'Selecciona una opción',
          selected: false,
          value: 0,
        });
        setBanks(banksState);
      })

      .catch((errorResponse) => console.log(errorResponse));
  }, []);

  const validateDocumentNumber = (value) => {
    const regexAlfa = /[^A-Za-z0-9]/g;
    var regexNumeric = /^[0-9\b]+$/;

    if (valuesSelected?.document?.regex === 'alfa' && !regexAlfa.test(value)) {
      setValuesSelected({
        ...valuesSelected,
        number: value,
      });
    }

    if (
      (valuesSelected?.document?.regex === 'num' && regexNumeric.test(value)) ||
      value === ''
    ) {
      setValuesSelected({
        ...valuesSelected,
        number: value.replace(/\D/g, ''),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !valuesSelected?.bank?.id ||
      !valuesSelected?.clientType?.id ||
      !valuesSelected?.document?.id ||
      valuesSelected?.number === '' 
      // || !hasAddress
    ) {
      setErrorPSE({
        ...errorPSE,
        emptyBank: !valuesSelected?.bank?.id ? true : false,
        emptyClientType: !valuesSelected?.clientType?.id ? true : false,
        emptyDocumentType: !valuesSelected?.document?.id ? true : false,
        emptyDocument: valuesSelected?.number === '' ? true : false,
      });

      /*if (!hasAddress) {
        setEmptyAddressFunction(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }*/

      return;
    }

    setLoadingButton(true);

    const pseData = {
      codeBankPSE: valuesSelected?.bank?.value,
      userTypePSE: valuesSelected?.clientType?.value,
      docTypePSE: valuesSelected?.document?.value,
      docValuePSE: valuesSelected?.number,
      docTypeCard: '',
      docValueCard: '',
    };

    dataToPSE.pseData = pseData;
    dataToPSE.paymentMethodType = 'pse';
    dataToPSE.country = 'Colombia';
    dataToPSE.cookie = localStorage.getItem('cookie');
    dataToPSE.deviceSessionId = localStorage.getItem('deviceSessionId');
    dataToPSE.source = 'link';
    dataToPSE.origin = origin;


    axiosPost(dataToPSE)
      .then((response) => {
        if (response.data.pseLink) {
          setDataPSE(response.data);
          setFormPSE(false);
          setPSELink(response.data.pseLink);
        } else {
          if (response.data.status === 'DECLINED') {
            setLoadingButton(false);
            setErrorPSE({
              ...errorPSE,
              error: 'Tu banco ha declinado la generación del pago',
              status: true,
            });
          } else {
            if (!response.data.success) {
              setLoadingButton(false);
              setErrorPSE({
                ...errorPSE,
                error: 'No se ha podido generar el link de pago',
                status: true,
              });
            }
          }
        }
      })
      .catch((error) => {
        setLoadingButton(false);
        setErrorPSE({
          ...errorPSE,
          error: 'No se ha podido generar el link de pago',
          status: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    if (errorPSE.status) {
      setTimeout(() => {
        setErrorPSE({
          ...errorPSE,
          error: '',
          status: false,
        });
      }, 5000);
    }
  }, [errorPSE]);

  return (
    <>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{titlePSE}</p>
        <img
          className={styles.logo}
          width={61}
          height={24}
          src={pseLogo}
          alt="PSE Logo"
        />
      </div>
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={stylesForm.form}>
          <div className={stylesForm.installments}>
            <Dropdown
              id="documnent_banks"
              fullWidth
              label="Banco"
              options={banks}
              value={valuesSelected.bank}
              onChange={(selection) => {
                if (selection.id !== 0) {
                  setValuesSelected({
                    ...valuesSelected,
                    bank: {
                      id: selection.id,
                      label: selection.label,
                      selected: true,
                      value: selection.value,
                    },
                  });
                }
              }}
            />
            {errorPSE.emptyBank && (
              <div className="ErrorContainer">
                <span className="material-icons ErrorIcon">error</span>
                <span className="ErrorMessage">Selecciona tu banco</span>
              </div>
            )}
          </div>
          <div className={stylesForm.installments}>
            <Dropdown
              id="documnent_client"
              fullWidth
              label="Tipo de cliente"
              options={formPSE.clientType}
              value={valuesSelected.clientType}
              onChange={(selection) => {
                if (selection.id !== 0) {
                  setValuesSelected({
                    ...valuesSelected,
                    clientType: {
                      id: selection.id,
                      label: selection.label,
                      selected: true,
                      value: selection.value,
                    },
                  });
                }
              }}
            />
            {errorPSE.emptyClientType && (
              <div className="ErrorContainer">
                <span className="material-icons ErrorIcon">error</span>
                <span className="ErrorMessage">Selecciona tipo de cliente</span>
              </div>
            )}
          </div>
          <div className={stylesForm.installments}>
            <Dropdown
              id="documnent_id"
              fullWidth
              label="Identificación"
              options={formPSE.document}
              value={valuesSelected.document}
              onChange={(selection) => {
                if (selection.id !== 0) {
                  setValuesSelected({
                    ...valuesSelected,
                    document: {
                      id: selection.id,
                      label: selection.label,
                      selected: true,
                      value: selection.value,
                      regex: selection.regex,
                    },
                    number: '',
                  });
                }
              }}
            />
            {errorPSE.emptyDocumentType && (
              <div className="ErrorContainer">
                <span className="material-icons ErrorIcon">error</span>
                <span className="ErrorMessage">
                  Selecciona tipo de identificación
                </span>
              </div>
            )}
          </div>
          <div className={stylesForm.inputMargin}>
            <Input
              id="document_number"
              type="text"
              error={null}
              label="Número del documento"
              disabled={!valuesSelected.document.id}
              placeholder="4474613215647"
              value={valuesSelected.number}
              onChange={(e) => validateDocumentNumber(e.target.value)}
              variant="outline"
              pattern={null}
              fullWidth
              maxLength={20}
            />
            {errorPSE.emptyDocument && (
              <div className="ErrorContainer">
                <span className="material-icons ErrorIcon">error</span>
                <span className="ErrorMessage">
                  Ingresa tu número de documento
                </span>
              </div>
            )}
          </div>
          <div className={stylesForm.button}>
            {errorPSE.status && (
              <div className={stylesForm.banner}>
                <Banner
                  content="Intentalo nuevamente o selecciona un método de pago distinto."
                  fullWidth
                  title={errorPSE.error}
                  variant="critical"
                />
              </div>
            )}
            <Button
              id="Check_Flow_PSE"
              color="blue"
              fullWidth
              isButton
              label={buttonPSE1}
              rel="noopener noreferrer"
              size="medium"
              variant="filled"
              type="submit"
              loading={loadingButton}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default PSEForm;
